import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		sponsorId: '' // 立项单位Id
	},
	mutations: {
		// 设置立项单位Id
		setSponsorId(state, sponsorId) {
			state.sponsorId = sponsorId
		}
	},
	actions: {},
	modules: {}
})